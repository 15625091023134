import { CircularProgress } from "@mui/material";

export const Loading = () => (
    <div
        style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <CircularProgress color="primary" />
    </div>
);
