export const APP_VERSION = import.meta.env.VITE_APP_VERSION as string | undefined;
export const BUILD_VER = import.meta.env.VITE_APP_BUILD_VERSION as string | undefined;
export const IS_PRODUCTION = import.meta.env.PROD as boolean;
export const MODE = import.meta.env.MODE as string;

export const productionMode = "production";
export const developmentMode = "development";
export const stagingMode = "staging";
export const demoMode = "demo";

export const isProductionMode = () => MODE === productionMode;
export const isDevelopmentMode = () => MODE === developmentMode;
export const isStagingMode = () => MODE === stagingMode;
export const isDemoMode = () => MODE === demoMode;

export const API_URL = import.meta.env.VITE_API_URL as string | undefined;

export const HOST_APP = import.meta.env.VITE_HOST_APP as string | undefined;

export const KEYCLOAK_REALM = import.meta.env.VITE_KEYCLOAK_REALM as string | undefined;
export const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_KEYCLOAK_CLIENT_ID as string | undefined;
export const KEYCLOAK_URL = import.meta.env.VITE_KEYCLOAK_URL as string | undefined;

export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string | undefined;

export const S3_ACCESS_KEY_ID = import.meta.env.VITE_S3_ACCESS_KEY_ID as string | undefined;
export const S3_SECRET_ACCESS_KEY = import.meta.env.VITE_S3_SECRET_ACCESS_KEY as string | undefined;
export const S3_ENDPOINT = import.meta.env.VITE_S3_ENDPOINT as string | undefined;
export const S3_REGION = import.meta.env.VITE_S3_REGION as string | undefined;
export const S3_BUCKET_NAME = import.meta.env.VITE_S3_BUCKET_NAME as string | undefined;

export const HELP_CENTER_URL = import.meta.env.VITE_HELP_CENTER_URL as string | undefined;
export const HELP_CENTER_CONTENT_API_KEY = import.meta.env.VITE_HELP_CENTER_CONTENT_API_KEY as string | undefined;

export const CDN_URL = import.meta.env.VITE_CDN_URL as string | undefined;
