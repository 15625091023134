import Keycloak from "keycloak-js";
import { IConfig } from "@/api/AuthorizedApiBase";
import {
    isDevelopmentMode,
    isProductionMode,
    KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REALM,
    KEYCLOAK_URL,
} from "@/utils/enviroment";

const keyCloak = new Keycloak({
    realm: KEYCLOAK_REALM || "",
    clientId: KEYCLOAK_CLIENT_ID || "",
    url: KEYCLOAK_URL || "",
});

let isInitialized = false;

const initKeycloak = async () => {
    if (!isInitialized) {
        await keyCloak.init({
            silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
            pkceMethod: "S256",
            enableLogging: !isProductionMode(),
            onLoad: isDevelopmentMode() ? "login-required" : "check-sso",
        });

        isInitialized = true;
    }

    if (!keyCloak.authenticated) {
        keyCloak.login();
    }
};

const doLogout = async () => {
    await initKeycloak();
    keyCloak.logout();
};

const getToken = async () => {
    if (window.navigator.onLine) {
        await initKeycloak();
        await keyCloak.updateToken(5);
        return keyCloak.token;
    }

    return undefined;
};

const getAuthorizationHeader = async () => {
    const token = await getToken();
    return `Bearer ${token}`;
};

const APIConfig: IConfig = {
    getAuthorization: getAuthorizationHeader,
};

const UserService = {
    initKeycloak,
    doLogout,
    getToken,
    APIConfig,
};

export default UserService;
